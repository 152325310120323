<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("imageEditor.images") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="!uploadWindow">
            <div class="row" v-for="(row, key) in rows" :key="key">
              <h3>{{ row.name }}</h3>
              <div>
                <draggable
                  class="dragArea row custom-input pt-2"
                  tag="div"
                  :list="row.images"
                  :group="{ name: 'g1' }"
                  item-key="id"
                  v-bind="dragOptions"
                  :forceFallback="true"
                  :scroll="true"
                  :scrollSensitivity="100"
                  v-on:end="moveEvent(row.id)"
                >
                  <template #item="{ element }">
                    <div class="col-2 my-2 img-uploader-thumbnail">
                      <img
                        class="img-thumbnail"
                        :src="imageUrl(element)"
                        :alt="element.name"
                      />
                      <div class="img-btns">
                        <button
                          class="btn btn-outline-primary"
                          v-on:click="deleteImage(element)"
                        >
                          <BaseIcon name="trash" />
                        </button>
                        <button
                          class="btn spotlight btn-outline-primary"
                          :class="{ active: element.main }"
                          v-on:click="selectSpotlight(element)"
                        >
                          <BaseIcon name="star" />
                        </button>
                        <button
                          class="btn btn-outline-primary"
                          :class="{ active: element.in_page }"
                          v-on:click="
                            selectDisplaySettings(element, 2, !element.in_page)
                          "
                        >
                          <BaseIcon name="shop" />
                        </button>
                        <button
                          class="btn btn-outline-primary"
                          :class="{ active: element.in_meta }"
                          v-on:click="
                            selectDisplaySettings(element, 1, !element.in_meta)
                          "
                        >
                          <BaseIcon name="share" />
                        </button>
                        <button
                          class="btn btn-outline-primary"
                          :class="{ active: element.in_manufacturing }"
                          v-if="isVariant"
                          v-on:click="
                            selectDisplaySettings(
                              element,
                              3,
                              !element.in_manufacturing
                            )
                          "
                        >
                          <BaseIcon name="ruler" />
                        </button>
                      </div>
                    </div>
                  </template>
                  <template #footer>
                    <div class="col-2 my-2">
                      <button
                        class="img-thumbnail"
                        v-on:click="
                          selectedGroup = row;
                          uploadWindow = true;
                        "
                      >
                        <BaseIcon name="plus-circle" />
                      </button>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-3 text-start">
                <button
                  class="btn w-100 d-block"
                  v-on:click="uploadMenu = true"
                  :class="{
                    'btn-primary': uploadMenu,
                    'btn-simple': !uploadMenu,
                  }"
                >
                  {{ $t("imageEditor.uploadFile") }}
                </button>
                <button
                  class="btn w-100 d-block"
                  v-on:click="uploadMenu = false"
                  :class="{
                    'btn-primary': !uploadMenu,
                    'btn-simple': uploadMenu,
                  }"
                >
                  {{ $t("imageEditor.browseGallery") }}
                </button>
              </div>
              <div class="col" v-if="uploadMenu">
                <file-pond
                  name="image"
                  ref="pond"
                  label-idle="Drop files here..."
                  v-bind:allow-multiple="true"
                  accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, image/webp"
                  v-bind:files="myFiles"
                  v-on:init="handleFilePondInit"
                  :server="server"
                  :credits="false"
                />
              </div>
              <div class="col" v-else>
                <div class="row">
                  <div
                    class="col-3 my-2 img-uploader-thumbnail"
                    v-for="image in selectable"
                    :key="image.id"
                    v-on:click="addImageToProduct(image)"
                  >
                    <img
                      class="img-thumbnail"
                      :src="imageUrl(image)"
                      :alt="image.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="text-end mt-4">
              <button class="btn btn-primary" v-on:click="uploadWindow = false">
                {{ $t("imageEditor.back2") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import http from "../../modules/http";
import tenant from "../../modules/tenant";
const BaseIcon = defineAsyncComponent(() => import("../icons/BaseIcon.vue"));
const bootstrap = require("bootstrap");
import draggable from "vuedraggable";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "ImageEditor",
  props: {
    show: Boolean,
    id: Number,
    isVariant: Boolean,
    type: String,
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL;
    },
  },
  emits: ["changeImage", "update"],
  data: function () {
    return {
      uploadWindow: false,
      uploadMenu: true,
      myFiles: [],
      rows: [],
      selectable: [],
      selectedGroup: null,
      dragOptions: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      },
      server: {
        process: this.upload,
      },
    };
  },
  watch: {
    show() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        myModal.show();
        if (this.isVariant) {
          http
            .fetch("/files/variant-images?variant=" + this.id)
            .then((data) => {
              this.rows = [data.variant];
              this.selectable = data.product;
            });
        } else {
          http
            .fetch(`/files/${this.type ?? "product"}-images?product=${this.id}`)
            .then((data) => {
              this.rows = data.images;
              this.selectable = data.related;
            });
        }
      }
    },
  },
  methods: {
    deleteImage(image) {
      let delImage = {
        id: this.id,
        image: image.id,
      };

      if (this.isVariant) {
        delImage.is_variant = true;
      }
      if (this.type === "bundle") {
        delImage.is_bundle = true;
      }

      if (image.variant_id > 0) {
        delImage.variant_id = image.variant_id;
      }

      http.fetch("/products/delete-image", delImage).then((data) => {
        let e = this.rows[image.variant_id ?? 0].images.indexOf(image);
        if (e > -1) {
          this.rows[image.variant_id ?? 0].images.splice(e, 1);
        }
        if (data.need_delete) {
          const request = new XMLHttpRequest();
          const formData = new FormData();
          formData.append("token", data.token);
          formData.append("path", data.image.path);

          request.open(
            "POST",
            process.env.VUE_APP_MEDIA_API +
              "/" +
              tenant.getTenantName() +
              "/images/delete"
          );

          request.send(formData);
        }
      });
    },
    selectDisplaySettings(image, type, active) {
      let addImage = {
        id: this.id,
        image: image.id,
      };
      if (type === 1) {
        addImage.in_meta = active;
        image.in_meta = active;
      } else if (type === 2) {
        addImage.in_page = active;
        image.in_page = active;
      } else if (type === 3) {
        addImage.in_manufacturing = active;
        image.in_manufacturing = active;
      }

      if (this.isVariant) {
        addImage.is_variant = true;
      }
      if (this.type === "bundle") {
        addImage.is_bundle = true;
      }

      if (image.variant_id > 0) {
        addImage.variant_id = image.variant_id;
      }

      http.fetch("/products/display-image", addImage).then(() => {});
    },
    selectSpotlight(image) {
      for (const imageElement of this.rows[image.variant_id ?? 0].images) {
        imageElement.main = false;
      }
      image.main = true;

      let addImage = {
        id: this.id,
        image: image.id,
      };

      if (this.isVariant) {
        addImage.is_variant = true;
      }
      if (this.type === "bundle") {
        addImage.is_bundle = true;
      }

      if (image.variant_id > 0) {
        addImage.variant_id = image.variant_id;
      }

      http.fetch("/products/primary-image", addImage).then(() => {});

      this.$emit(
        "changeImage",
        this.mediaUrl + "/" + image.path + "/262-" + image.name + ".webp"
      );
    },
    addImageToProduct(image) {
      let addImage = {
        id: this.id,
        image: image.id,
      };

      if (this.isVariant) {
        addImage.is_variant = true;
      }
      if (this.type === "bundle") {
        addImage.is_bundle = true;
      }

      if (image.variant_id > 0) {
        addImage.variant_id = image.variant_id;
      }

      const group = this.selectedGroup;
      if (group && group.id) {
        addImage.variant_id = group.id;
        this.rows[group.id].images.push(image);
      } else {
        this.rows[0].images.push(image);
      }

      this.uploadWindow = false;
      this.$emit("update", addImage);

      http.fetch(`/products/add-image`, addImage);
    },
    moveEvent(groupId) {
      let addImage = {
        id: this.id,
        images: this.rows[groupId ?? 0].images.map((e) => e.id),
      };
      if (groupId > 0) {
        addImage.variant_id = groupId;
      }


      if (this.type === "bundle") {
        addImage.is_bundle = true;
      }
      http.fetch(`/products/move-images`, addImage);
    },
    imageUrl(image) {
      if (image.remote) {
        return image.path;
      }
      return this.mediaUrl + "/" + image.path + "/262-" + image.name + ".webp";
    },
    upload(fieldName, file, metadata, load, error, progress, abort) {
      const request = new XMLHttpRequest();
      const _this = this;
      const group = _this.selectedGroup;
      let send = {
        type: this.type ?? "product",
        id: _this.id,
        images: [file.name],
      };
      if (_this.isVariant) {
        send.is_variant = true;
      }
      if (_this.type === "bundle") {
        send.is_bundle = true;
      }
      if (group) {
        send.variant_id = group.id;
      }

      http.fetch("/files/upload", send).then((data) => {
        const formData = new FormData();
        formData.append(fieldName, file, file.name);
        formData.append("token", data.token);
        formData.append("album", data.album);
        formData.append("category", this.type ?? "product");

        request.open(
          "POST",
          process.env.VUE_APP_MEDIA_API +
            "/" +
            tenant.getTenantName() +
            "/images/upload"
        );

        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            let data2 = JSON.parse(request.responseText);

            let addImage = {
              id: _this.id,
              image: data2.operation.images[0].id,
            };

            if (_this.isVariant) {
              addImage.is_variant = true;
            }
            if (_this.type === "bundle") {
              addImage.is_bundle = true;
            }

            console.log(group);

            if (group && group.id) {
              addImage.variant_id = group.id;
              _this.rows[group.id].images.push(data2.operation.images[0]);
            } else {
              _this.rows[0].images.push(data2.operation.images[0]);
            }

            _this.$emit("update", addImage);

            http.fetch(`/products/add-image`, addImage);

            load(request.responseText);
          } else {
            error("oh no");
          }
        };

        request.send(formData);
      });

      return {
        abort: () => {
          request.abort();
          abort();
        },
      };
    },
    handleFilePondInit: function () {
      console.log("FilePond has initialized");
    },
  },
  components: { BaseIcon, FilePond, draggable },
};
</script>
